<template>
  <div class="app">
    <!-- 出口 -->
    <router-view v-if="isShow"></router-view>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "App",
  provide() {
    return {
      reload: this.reload,
      Path: "",
    };
  },
  data() {
    return {
      isShow: true,
    };
  },
  mounted() {},
  created() {},
  methods: {
    reload() {
      this.isShow = false;
      this.$nextTick(() => {
        this.isShow = true;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.app {
  height: 100%;
}
</style>
