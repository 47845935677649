<template>
  <div>
    <div class="home-page">
      <el-row :gutter="20">
        <el-col :span="18">
          <div class="big-page">
            <div class="page-title">{{ translateTitle("累计数据") }}</div>
            <div class="home">
              <div class="disal">
                <span class="home-title">{{ translateTitle("累计用户") }}</span>
                <!-- <span class="home-num">{{ userTotalCount }}</span> -->
				<span class="home-num">452,664</span>
              </div>
              <div v-loading="selectLoading">
                <div
                  :class="['click-btn', { bcfb: time === 'MONTH' }]"
                  @click="selectCategory('MONTH')"
                >
                  {{ translateTitle("月") }}
                </div>
                <div
                  :class="['click-btn', { bcfb: time === 'DATES' }]"
                  @click="selectCategory('DATES')"
                >
                  {{ translateTitle("日") }}
                </div>
                <div
                  :class="['click-btn', { bcfb: time === 'WEEK' }]"
                  @click="selectCategory('WEEK')"
                >
                  {{ translateTitle("周") }}
                </div>
              </div>
            </div>
            <div class="chart">
              <div id="box" class="box"></div>
            </div>

            <div class="left-card">
				<el-row :gutter="20">
				  <el-col :span="8">
				    <div class="card-item">
				      <el-row>
				        <el-col :span="6">
				          <span class="logo"><span></span></span>
				        </el-col>
				        <el-col :span="18">
				          <span class="title">
				            {{ translateTitle("Mastercard充值手续费") }}</span
				          >
				          <div class="num">
				            2,589,952
				          </div>
				        </el-col>
				      </el-row>
				    </div>
				  </el-col>
				  <el-col :span="8">
				    <div class="card-item">
				      <el-row>
				        <el-col :span="6">
				          <span class="logo"><span></span></span>
				        </el-col>
				        <el-col :span="18">
				          <span class="title">
				            {{ translateTitle("Mastercard月费")}}</span
				          >
				          <div class="num">
				            625,736,18
				          </div>
				        </el-col>
				      </el-row>
				    </div>
				  </el-col>
				  <el-col :span="8">
				    <div class="card-item">
				      <el-row>
				        <el-col :span="6">
				          <span class="logo"><span></span></span>
				        </el-col>
				        <el-col :span="18">
				          <span class="title">{{
				            translateTitle("Mastercard转账手续费")
				          }}</span>
				          <div class="num">
				            89,340
				          </div>
				        </el-col>
				      </el-row>
				    </div>
				  </el-col>
				</el-row>
				<el-row :gutter="20">
				  <el-col :span="8">
				    <div class="card-item">
				      <el-row>
				        <el-col :span="6">
				          <span class="logo"><span></span></span>
				        </el-col>
				        <el-col :span="18">
				          <span class="title">
				            {{ translateTitle("Mastercard提现费用") }}</span
				          >
				          <div class="num">
				            198,456
				          </div>
				        </el-col>
				      </el-row>
				    </div>
				  </el-col>
				  <el-col :span="8">
				    <div class="card-item">
				      <el-row>
				        <el-col :span="6">
				          <span class="logo"><span></span></span>
				        </el-col>
				        <el-col :span="18">
				          <span class="title">
				            {{ translateTitle("Mastercard虚拟账户费用") }}</span
				          >
				          <div class="num">
				            289,678
				          </div>
				        </el-col>
				      </el-row>
				    </div>
				  </el-col>
				  <el-col :span="8">
				    <div class="card-item">
				      <el-row>
				        <el-col :span="6">
				          <span class="logo"><span></span></span>
				        </el-col>
				        <el-col :span="18">
				          <span class="title">{{
				            translateTitle("Mastercard外汇费用")
				          }}</span>
				          <div class="num">
				            57,930
				          </div>
				        </el-col>
				      </el-row>
				    </div>
				  </el-col>
				</el-row>
              <el-row :gutter="20">
                <el-col :span="8">
                  <div class="card-item">
                    <el-row>
                      <el-col :span="6">
                        <span class="logo"><span></span></span>
                      </el-col>
                      <el-col :span="18">
                        <span class="title">
                          {{ translateTitle("累计账户余额") }}(USD)</span
                        >
                        <div class="num">
                          <!-- {{ totalStaticsResponse.totalUsdtBalance }} -->
						  11,635,657,43
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="card-item">
                    <el-row>
                      <el-col :span="6">
                        <span class="logo"><span></span></span>
                      </el-col>
                      <el-col :span="18">
                        <span class="title">
                          {{ translateTitle("累计账户充值") }}</span
                        >
                        <div class="num">
                          <!-- {{ totalStaticsResponse.totalRecharge }} -->
						  1,296,421,98
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="card-item">
                    <el-row>
                      <el-col :span="6">
                        <span class="logo"><span></span></span>
                      </el-col>
                      <el-col :span="18">
                        <span class="title">{{
                          translateTitle("累计账户消费")
                        }}</span>
                        <div class="num">
                          <!-- {{ totalStaticsResponse.totalConsume }} -->
						  1,563,233,47
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="8">
                  <div class="card-item">
                    <el-row>
                      <el-col :span="6">
                        <span class="logo"><span></span></span>
                      </el-col>
                      <el-col :span="18">
                        <span class="title">{{
                          translateTitle("累计加密账户提现")
                        }}</span>
                        <div class="num">
                          <!-- {{ totalStaticsResponse.totalCryptoCash }} -->
						  1,467,553
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="card-item">
                    <el-row>
                      <el-col :span="6">
                        <span class="logo"><span></span></span>
                      </el-col>
                      <el-col :span="18">
                        <span class="title">{{
                          translateTitle("累计加密账户余额")
                        }}</span>
                        <div class="num">
                          <!-- {{ totalStaticsResponse.totalCryptoBalance }} -->
						  95,936,456
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="card-item">
                    <el-row>
                      <el-col :span="6">
                        <span class="logo"><span></span></span>
                      </el-col>
                      <el-col :span="18">
                        <span class="title">{{
                          translateTitle("累计加密账户充值")
                        }}</span>
                        <div class="num">
                          <!-- {{ totalStaticsResponse.totalCryptoRecharge }} -->
						  567,321
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="card-item">
                    <el-row>
                      <el-col :span="6">
                        <span class="logo"><span></span></span>
                      </el-col>
                      <el-col :span="18">
                        <span class="title">{{
                          translateTitle("今日新增用户")
                        }}</span>
                        <div class="num" style="margin-top: 10px;">
                          <span class="title">{{
                          translateTitle("昨日")
                        }}</span> <span class="title">
						<!-- {{ todayStaticsNewResponse.yesterdayAddUser }} -->
						1,350
						</span>
                        </div>
                        <div class="num">
                          <!-- {{ todayStaticsNewResponse.todayAddUser }} -->
						  1,580
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="card-item">
                    <el-row>
                      <el-col :span="6">
                        <span class="logo"><span></span></span>
                      </el-col>
                      <el-col :span="18">
                        <span class="title">{{
                          translateTitle("交易用户")
                        }}</span>
                        <div class="num" style="margin-top: 10px;">
                          <span class="title">{{
                          translateTitle("今日")
                        }}</span> <span class="title">
						<!-- {{ todayStaticsNewResponse.totayTradeUser }} -->
						3,000
						</span>
                        </div>
                        <div class="num">
                          <!-- {{ todayStaticsNewResponse.totalTradeUser }} -->
						  1,201,867
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="card-item">
                    <el-row>
                      <el-col :span="6">
                        <span class="logo"><span></span></span>
                      </el-col>
                      <el-col :span="18">
                        <span class="title">{{
                          translateTitle("总用户量")
                        }}</span>
                        <div class="num" style="margin-top: 10px;">
                          <span class="title">{{
                          translateTitle("本月新增")
                        }}</span> <span class="title">
						<!-- {{ todayStaticsNewResponse.monthAddUser }} -->
						5,690
						</span>
                        </div>
                        <div class="num">
                          <!-- {{ todayStaticsNewResponse.totalAddUser }} -->
						  1,465,509
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
                <el-col :span="8">
                 <div class="card-item">
                    <el-row>
                      <el-col :span="6">
                        <span class="logo"><span></span></span>
                      </el-col>
                      <el-col :span="18">
                        <span class="title">{{
                          translateTitle("今日托管数额")
                        }}</span>
                        <div class="num">
                          <!-- {{ todayStaticsNewResponse.todayZyAmount }} -->
						  1,361,892,34
						  {{ 'USDT' }}
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="card-item">
                    <el-row>
                      <el-col :span="6">
                        <span class="logo"><span></span></span>
                      </el-col>
                      <el-col :span="18">
                        <span class="title">{{
                          translateTitle("今日加密货币交易数额")
                        }}</span>
                        <div class="num">
                          <!-- {{ todayStaticsNewResponse.todayTradeAmount }} -->
						  1,847,542,67
						  {{ 'USDT' }}
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="card-item">
                    <el-row>
                      <el-col :span="6">
                        <span class="logo"><span></span></span>
                      </el-col>
                      <el-col :span="18">
                        <span class="title">{{
                          translateTitle("今日银行交易数额")
                        }}</span>
                        <div class="num">
                          <!-- {{ todayStaticsNewResponse.bankTodayAmount }} -->
						  1,532,452,07
						  {{ 'USDT' }}
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="right-content">
            <Header />
            <div class="today-title">{{ translateTitle("今日数据") }}</div>
            <div class="content">
              <div class="content-item">
                <span class="title">{{ translateTitle("新增用户") }}</span>
                <!-- <p class="num">{{ todayStaticsResponse.todayAddUserCount }}</p> -->
				<p class="num">1,580</p>
              </div>
              <div class="content-item">
                <span class="title"
                  >{{ translateTitle("账户充值") }}(USD)</span
                >
                <p class="num">
                  <!-- {{ todayStaticsResponse.accountRechargeUsdt }} -->
				  3,754,980
                </p>
              </div>
              <div class="content-item">
                <span class="title">{{ translateTitle("账户消费") }}</span>
                <p class="num">
				<!-- {{ todayStaticsResponse.accountConsume }} -->
				1,437,782
				</p>
              </div>
            </div>
            <div class="content">
              <div class="content-item">
                <span class="title">{{ translateTitle("加密账户充值") }}</span>
                <p class="num">
                  <!-- {{ todayStaticsResponse.cryptoAccountRecharge }} -->
				  567,321
                </p>
              </div>
              <div class="content-item">
                <span class="title">{{ translateTitle("加密账户提现") }}</span>
                <p class="num">
				<!-- {{ todayStaticsResponse.cryptoAccountCash }} -->
				1,467,553
				</p>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts/core";
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from "echarts/components";
import { LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { translateTitle } from "@/utils/i18n";
import { indexData, indexUserCountCharts,indexnewStaics} from "@/api/index";

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
]);
export default {
  data() {
    return {
      time: "WEEK",
      todayStaticsResponse: {},
      totalStaticsResponse: {},
      todayStaticsNewResponse:{},
      userTotalCount: "",
      selectLoading: false,
    };
  },
  mounted() {
    this.getData();
    this.getnewData();
    this.getChartData();
  },
  methods: {
    translateTitle,
    //获取首页数据除图表
    async getData() {
      let res = await indexData();
      const { todayStaticsResponse, totalStaticsResponse, userTotalCount } =
        res.data.data;
      this.userTotalCount = userTotalCount;
      this.totalStaticsResponse = totalStaticsResponse;
      this.todayStaticsResponse = todayStaticsResponse;
    },
    async getnewData() {
      let res = await indexnewStaics();
      // const { todayStaticsResponseNew} =
      //   res.data.data;
      this.todayStaticsNewResponse = res.data.data;
    },
    //获取首页图表信息
    async getChartData() {
      this.selectLoading = true;
      let res = await indexUserCountCharts(this.time);
      let xData = [];
      let yData = [];
      if (res.data.code == 200) {
        this.selectLoading = false;
		let increment = 20
		res.data.data.forEach(function(record, index) {
		  // 将 userCount 转换为数字，如果转换失败则使用默认值100
		  let userCount = parseInt(record.userCount, 10) || 100;
		  // 计算新的 userCount 值，从100开始，每次循环增加 increment 的值
		  record.userCount = 100 + (index * increment);
		});
        res.data.data.map((item) => {
          xData.push(item.dateStr);
          yData.push(item.userCount);
        });
        this.selectLoading = false;
        this.drawLine(xData, yData);
        
      }
    },
    selectCategory(item) {
      this.time = item;
      this.getChartData();
    },
    // 绘制echarts
    drawLine(xData, yData) {
      var chartDom = document.getElementById("box");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        color: ["#F79E1B"],
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: xData,
            axisLine: {
              lineStyle: {
                color: "#B8C2D6",
              },
            },
            axisLabel: {
              interval: 0,
              // rotate: 30
              formatter: (value) => {
                if (value.length >= 4) {
                  value = value.slice(0, 4) + "...";
                }
                return value;
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            splitNumber: 20,
            axisLine: {
              lineStyle: {
                color: "#B8C2D6",
              },
            },
          },
        ],
        series: [
          {
            type: "line",
            stack: "Total",
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#F1E4CB",
                },
                {
                  offset: 1,
                  color: "#FBFAF9",
                },
              ]),
            },
            emphasis: {
              focus: "series",
            },
            data: yData,
          },
        ],
      };
      option && myChart.setOption(option);
      //    window.addEventListener("resize",myChart);
    },
  },
};
</script>

<style lang="less" scoped>
.home-page {
  .el-row {
    background: #f8fafb;
  }
  .el-col-18 {
    background-color: #fff;
  }
  .left-card {
    .el-row {
      background: #fff;
      padding: 15px;
    }
  }
}
.home {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .home-title {
    font-size: 35px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #b9bfca;
    margin-right: 30px;
  }
  .home-num {
    font-size: 48px;
    font-family: DIN;
    font-weight: bold;
    color: #31363e;
  }
  .click-btn {
    width: 72px;
    height: 34px;
    border-radius: 10px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #b9bfca;
    text-align: center;
    line-height: 34px;
    display: inline-block;
    margin-left: 20px;
    cursor: pointer;
  }
  .bcfb {
    background: #fbf3e7;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #f79e1b;
  }
}
.box {
  width: 100%;
  height: 400px;
  background-color: #f8fafb;
  border-radius: 6px;
}
.card-item {
  width: 100%;
  background: #fbfcfd;
  //   background-color: red;
  border-radius: 10px;
  padding: 40px 30px;
  margin-top: 20px;
  .logo {
    width: 22px;
    height: 22px;
    background: #fff6e8;
    border-radius: 3px;
    display: inline-block;
    text-align: center;
    line-height: 22px;
    span {
      width: 10px;
      height: 10px;
      background: #f79e1b;
      border-radius: 3px;
      display: inline-block;
    }
  }
  .title {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #b9bfca;
  }
  .num {
    font-size: 28px;
    font-family: DIN;
    font-weight: bold;
    color: #31363e;
    margin-top: 30px;
    word-break: break-word;
  }
}

.right-content {
  width: 100%;
  height: 100%;
  background: #f8fafb;
  padding: 30px 10px;
  .today-title {
    font-size: 27px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #31363e;
    margin-top: 20px;
  }
  .content {
    margin-top: 20px;
    border-top: 1px solid #ebf2f4;
    display: flex;
    flex-wrap: wrap;
    .content-item {
      width: 50%;
      margin-top: 20px;
      margin-bottom: 20px;
      .title {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #b9bfca;
      }
      .num {
        font-size: 28px;
        font-family: DIN;
        font-weight: bold;
        color: #31363e;
        margin-top: 10px;
        padding-left: 20px;
        position: relative;
        &::after {
          width: 6px;
          height: 20px;
          background: #16d0ff;
          border-radius: 3px;
          content: "";
          position: absolute;
          top: 6px;
          left: 0;
        }
      }
    }
  }
}
</style>
