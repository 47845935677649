<template>
  <el-form
    ref="form"
    class="login-form1"
    :model="setForm"
    :rules="setRules"
    :hide-required-asterisk="true"
  >
    <div class="title-tips">{{ translateTitle("common.resetPassword") }}</div>
    <el-tabs v-model="activeName" :stretch="true" @tab-click="handleChange">
      <el-tab-pane :label="translateTitle('common.tel')" name="first" />
      <el-tab-pane :label="translateTitle('邮箱')" name="second" />
    </el-tabs>
    <div v-if="activeName === 'first'">
      <el-form-item
        prop="userName"
        label-position="top"
        :label="translateTitle('common.resetPassword1')"
      >
        <el-input
          v-model.trim="setForm.userName"
          onfocus="this.removeAttribute('readonly');"
          :placeholder="translateTitle('common.enterNewPass')"
          type="text"
          clearable
        >
          <!-- <template #prefix>
            <span class="svg-container">
              <i class="el-icon-lock"></i>
            </span>
          </template> -->
        </el-input>
      </el-form-item>
      <el-form-item prop="password" :label="translateTitle('common.enterTel')">
        <el-input
          ref="password"
          v-model="setForm.password"
          onfocus="this.removeAttribute('readonly');"
          :placeholder="translateTitle('common.PEnterTel')"
          type="password"
          clearable
        >
          <!-- <template #prefix>
            <span class="svg-container">
              <i class="el-icon-phone" />
            </span>
          </template> -->
        </el-input>
      </el-form-item>
      <el-form-item prop="SMSCode">
        <div class="label">{{ translateTitle("common.code") }}</div>
        <div class="tow-input">
          <el-input
            ref="password"
            v-model="setForm.password"
            onfocus="this.removeAttribute('readonly');"
            :placeholder="translateTitle('common.EnterCode')"
            type="password"
            clearable
          >
            <!-- <template #prefix>
              <span class="svg-container">
                <i class="el-icon-s-comment" />
              </span>
            </template> -->
          </el-input>
          <el-button
            class="send-code"
            :disabled="timeNum > 0"
            size="mini"
            type="text"
            @click="handleGetCode"
          >
            {{
              timeNum > 0
                ? `${translateTitle("common.resend")}(${timeNum}s)`
                : `${translateTitle("common.sendCode")}`
            }}
          </el-button>
        </div>
      </el-form-item>
    </div>
    <div v-else-if="activeName === 'second'">
      <el-form-item
        prop="userName"
        label-position="top"
        :label="translateTitle('common.resetPassword1')"
      >
        <el-input
          v-model.trim="setForm.userName"
          onfocus="this.removeAttribute('readonly');"
          :placeholder="translateTitle('common.enterNewPass')"
          type="text"
          clearable
        >
          <!-- <template #prefix>
            <span class="svg-container">
              <i class="el-icon-lock"></i>
            </span>
          </template> -->
        </el-input>
      </el-form-item>
      <el-form-item prop="password" :label="translateTitle('common.enterEmail')">
        <el-input
          ref="password"
          v-model="setForm.password"
          onfocus="this.removeAttribute('readonly');"
          :placeholder="translateTitle('common.PlEnterEmail')"
          type="password"
          clearable
        >
          <!-- <template #prefix>
            <span class="svg-container">
              <i class="el-icon-phone" />
            </span>
          </template> -->
        </el-input>
      </el-form-item>
      <el-form-item prop="SMSCode">
        <div class="label">{{translateTitle('common.emailCode')}}</div>
        <div class="tow-input">
          <el-input
            ref="password"
            v-model="setForm.password"
            onfocus="this.removeAttribute('readonly');"
            :placeholder="translateTitle('common.PlEmailCode')"
            type="password"
            clearable
            class="aaaa"
          >
            <!-- <template #prefix>
              <span class="svg-container">
                <i class="el-icon-s-comment" />
              </span>
            </template> -->
          </el-input>
          <el-button
            class="send-code"
            :disabled="timeNum > 0"
            size="mini"
            type="text"
            @click="handleGetCode"
          >
            {{
              timeNum > 0
                ? `${translateTitle("common.resend")}(${timeNum}s)`
                : `${translateTitle("common.sendCode")}`
            }}
          </el-button>
        </div>
      </el-form-item>
    </div>
    <div class="mb15">
      <el-checkbox v-model="checked"></el-checkbox>&nbsp;&nbsp;{{
        translateTitle("common.agree")
      }}&nbsp;<span class="base-color">{{ translateTitle("common.termsAgreements") }}</span>
    </div>
    <div class="login-box-btns">
      <el-button :loading="loading" type="primary" @click="handleLogin">
        {{ translateTitle("common.login") }}
      </el-button>
    </div>
    <div class="login-bottom">
      <span>{{ translateTitle("common.existingAccount") }}?&nbsp;&nbsp;</span>
      <span class="base-color" @click="handleToLogin">{{
        translateTitle("common.quickLogin")
      }}</span>
    </div>
  </el-form>
</template>

<script>
export default {
  data() {
    const validateUsername = (rule, value, callback) => {
      if ("" === value) callback(new Error("邮箱不能为空"));
      else callback();
    };
    const validatePassword = (rule, value, callback) => {
      if ("" === value) callback(new Error("密码不能为空"));
      else callback();
    };
    // 验证码自定义验证规则
    const validateVerifycode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else {
        callback();
      }
    };
    const validatePhone = (rule, value, callback) => {
      if ("" === value) callback(new Error("手机号不能为空"));
      else if (
        !/^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/.test(
          value
        )
      ) {
        callback(new Error("请输入正确格式的手机号"));
      } else callback();
    };
    return {
      tabIndex: 1,
      checked: false,
      setForm: {
        email: "",
        password: "",
        verifKey: "",
        verifCode: "",
      },
      setRules: {
        email: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: validateUsername,
          },
        ],
        password: [
          { required: true, trigger: "change", validator: validatePassword },
        ],
        verifCode: [
          { required: true, trigger: "blur", validator: validateVerifycode },
        ],
      },
      passwordType: "password",
      capsTooltip: false,
      loading: false,
      activeName: "first",
      changeForm: {
        phone: "",
        loginPass: "",
        againLoginPass: "",
        verifCode: "",
      },
      code: "",
      show: true,
      timer: null,
      timeNum: 0,
      isLoading: false,
      loginType: 0, //0登录1是找回密码
    };
  },
  computed: {},
  watch: {
    // $route: {
    //   handler(route) {
    //     this.redirect = (route.query && route.query.redirect) || "/";
    //   },
    //   immediate: true,
    // },
  },
  created() {},
  mounted() {},
  methods: {
    //传值给父组件
    handleToLogin() {
      this.$emit("handleSonClick", 0);
    },
    //免费注册
    handleRegister() {
      this.$router.push("/register");
    },
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },

    handleLogin() {
      this.$refs.form.validate(async (valid) => {
        if (valid)
          try {
            this.loading = true;
            await this.login({
              loginName: this.setForm.userName,
              loginPass: md5(this.setForm.password),
              verifKey: this.setForm.verifKey,
              verifCode: this.setForm.verifCode,
            }).catch(() => {
              this.refreshCode();
            });
            this.loading = false;
            if (this.companyName) {
              this.$router.push({
                path: "/detail",
                query: {
                  companyName: this.companyName,
                },
              });
            } else {
              this.$router.push("/index");
            }
          } finally {
            this.loading = false;
          }
      });
      // this.loading = true
      // console.log(this.loginForm)
      // login({
      //   loginName: this.loginForm.userName,
      //   loginPass: md5(this.loginForm.password),
      // })
      //   .then(() => {})
      //   .finally(() => {
      //     this.loading = false
      //   })
    },

    handleChange(tab) {
      this.activeName = tab.name;
    },
    handleSumbitLogin(res) {
      if (res.userStatus === "0") {
        Object.assign(this.activeForm, res);
        this.dialogActive.visible = true;
      } else {
        let url = "/portal/vab1/notice";
        this.$router.push({
          path: url,
        });
      }
    },
    handleGetCode() {
      // if (!this.changeForm.phone) {
      //   this.$message.warning("请输入电话号码");
      // } else {
      // }
      // } else if (!this.loginForm.mobilePhone) {
      //   this.$message.warning('请输入手机号')
      // } else if (!this.loginForm.email) {
      //   this.$message.warning('请输入邮箱')
      // }
      // else {
      // const params = {
      //   userName: this.loginForm.userName,
      //   mobilePhone: this.loginForm.mobilePhone,
      //   email: this.loginForm.email,
      // }
      // api_SMSCode(params).then((res) => {
      //   if (res.code === 200) {
      // this.$message.success(`已发送，请注意查收`)
      //     this.loginForm.codeKey = res.data.codeKey
      this.timeNum = 60;
      this.timer = setInterval(() => {
        this.timeNum--;
        if (!this.timeNum) {
          clearInterval(this.timer);
        }
      }, 1000);
      //   } else {
      //     this.$message.error(res.msg)
      //   }
      // })
      // }
    },
    changePassword() {
      this.$refs.changeForm.validate((valid) => {
        if (valid) {
          console.log(this.changeForm);
        }
      });
    },
    handleCode(type) {},
    getuserGetUrl() {},
  },
};
</script>

<style lang="less" scoped>
.login-method {
  position: absolute;
  top: 5px;
  right: 58px;
}

.login-form1 {
  position: relative;
  max-width: 100%;
  padding: 2.5vh 3.5vh;
  margin: calc((100vh - 680px) / 2) 8vw;
  // background-color: #fff;
  border-radius: 8px;
  width: 540px;

  .title-tips {
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #31363e;
    margin-bottom: 30px;
  }

  // i {
  //   position: absolute;
  //   top: 8px;
  //   left: 5px;
  //   font-size: 16px;
  //   color: #d7dee3;
  //   cursor: pointer;
  //   user-select: none;
  // }

  ::v-deep {
    .el-tabs .el-tabs__item {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #777777;
    }
    .el-tabs .el-tabs__item.is-active {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000;
    }
    .el-tabs {
      margin-top: 15px;
      .el-tabs__nav-wrap::after {
        display: none;
      }
      .el-tabs__active-bar {
        display: none;
      }
      .el-tabs__item {
        font-size: 16px;
      }
    }
    .el-tabs .el-tabs__item {
      text-align: center;
    }
    .el-form-item {
      padding-right: 0;
      margin: 20px 0;
      color: #454545;
      background: transparent;
      border: 1px solid transparent;
      border-radius: 2px;
    }

    .el-form-item__label {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #b9bfca;
    }
    .el-input {
      box-sizing: border-box;

      input {
        height: 48px;
        // padding-left: 35px;
        line-height: 58px;
        background: #fff;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #777777;
        border: 2px solid #eef0f6;
      }
    }
  }
  .login-box-btns {
    display: flex;
    font-size: 16px;
    line-height: 24px;
    .el-button {
      flex: 1;
      height: 48px;
      border-radius: 6px;
      font-size: 16px;
      border-color: #e2e2e2;
      background: #e2e2e2;
      font-size: 19px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
    }
    .el-button + .el-button {
      margin-left: 25px;
    }
  }
  .svg-container {
    i {
      line-height: 30px;
    }
  }
}
.login-bottom {
  margin-top: 20px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #a6b5c4;
  text-align: right;
}
.tow-input {
  display: flex;
  ::v-deep {
    .el-input {
      width: 60% !important;
    }
  }
  .el-button.is-disabled.el-button--text{
    background-color: #999;
    color: #fff !important;
  }
  .send-code {
    width: 32%;
    background: #353a42;
    border-radius: 6px;
    text-align: center;
    color: #fff;
    margin-left: 8%;
     &:hover {
      cursor: pointer;
    }
    &:disabled {
      color: #999;
    }
  }
}
.label {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #b9bfca;
}
</style>
