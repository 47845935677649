import request from "@/utils/request";

//发送邮箱验证码
export const sendEmailCode = (data) =>
  request({
    url: "/code/sendEmailCode",
    method: "post",
    data,
  });

//发送手机验证码
export const sendPhoneCode = (data) =>
  request({
    url: "/code/sendPhoneCode",
    method: "post",
    data,
  });

//获取用户首页数据
export const indexData = () =>
  request({
    url: "/index_statics/data_statics",
    method: "GET",
  });

//获取用户图表数据
export const indexUserCountCharts = (dateType) =>
  request({
    url: `/index_statics/user_count_charts/${dateType}`,
    method: "GET",
  });


// 托管、用户、交易、银行数据统计
export const indexnewStaics = () =>
  request({
    url: "/index_statics/newStaics",
    method: "GET",
  });
