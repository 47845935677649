import Vue from "vue";
import VueRouter from "vue-router";
import local from "@/utils/local";

Vue.use(VueRouter);

// 重写路由的push 解决跳转当前导航的警告
const originPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return originPush.call(this, location).catch((err) => err);
};

// 引入组件
import Login from "../views/login/Login.vue";
import Layout from "../views/layout/Layout.vue";
import Home from "../views/home/index.vue";
import Register from "../views/login/register.vue";
import aa from "../views/404.vue";
/* 静态路由: 不用做权限的 写死的路由 */
const routes = [
  // 重定向
  { path: "/", redirect: "/login" },

  // 登录
  { path: "/login", component: Login },
  //注册
  { path: "/register", component: Register },
  {
    path: "*",
    component: () => import("../views/404.vue"),
  },
  {
    path: "/home",
    component: Layout,
    meta: {
      title: "后台首页",
      icon: require("@/assets/imgs/icon_shouye.png"),
    },
    children: [{ path: "", component: Home }],
  },
];

// 创建路由实例对象
const router = new VueRouter({
  routes, // 传入的是 静态路由
});

/* 全局路由前置守卫 */
router.beforeEach((to, from, next) => {
  // 判断是否登录 ( 看本地有没有令牌 如果有 代表登录过 且是登录成功的状态 如果没有 就是没有登录过 )
  let isLogin = local.get("tk") ? true : false;

  // 如果已经登录过了 直接放行
  if (isLogin) {
    next(); // 放行 已经登录过了 可以随便访问
  } else {
    // 防止死循环
    if (to.path === "/login") {
      next(); // 如果去的是登录 直接放行
    } else {
      next({ path: "/login" }); // 如果去的是别的页面  跳转到登录
    }
  }
});

// 动态路由
const dynamicRoutes = [
  //后台首页
  {
    path: "/home",
    component: Layout,
    meta: {
      title: "后台首页",
      icon: require("@/assets/imgs/icon_shouye.png"),
    },
    children: [{ path: "", component: Home }],
  },
  // 后台设置
  {
    path: "/setting",
    component: Layout,
    redirect: "/setting/menu",
    meta: {
      title: "后台设置",
      icon: require("@/assets/imgs/icon_shezhi(1).png"),
    },
    children: [
      {
        path: "/setting/menu",
        component: () => import("@/views/setting/menu/index"),
        meta: { title: "菜单管理" },
      },
      {
        path: "/setting/role",
        component: () => import("@/views/setting/role/index"),
        meta: { title: "权限管理" },
      },
      {
        path: "/setting/authority",
        component: () => import("@/views/setting/authority/index"),
        meta: { title: "角色管理" },
      },
    ],
  },

  //SaaS管理
  {
    path: "/saas",
    component: Layout,
    redirect: "/saas/customerManagement",
    meta: {
      title: "SaaS管理",
      icon: require("@/assets/imgs/icon_sass.png"),
    },
    children: [
      {
        path: "/saas/customerManagement",
        component: () => import("@/views/SaaS/customerManagement"),
        meta: { title: "客户管理" },
      },
      {
        path: "/saas/customerDataS",
        component: () => import("@/views/SaaS/customerDataS"),
        meta: { title: "客户数据统计" },
      },
    ],
  },
  //后台数据统计
  {
    path: "/data",
    component: Layout,
    redirect: "/data/recharge",
    meta: {
      title: "数据统计",
      icon: require("@/assets/imgs/icon_shujutongji.png"),
    },
    children: [
      {
        path: "/data/recharge",
        component: () => import("@/views/dataStatistics/recharge"),
        meta: { title: "每日充值" },
      },
      {
        path: "/data/transfer",
        component: () => import("@/views/dataStatistics/transfer"),
        meta: { title: "每日转账" },
      },
      {
        path: "/data/buyingCoins",
        component: () => import("@/views/dataStatistics/buyingCoins"),
        meta: { title: "每日买币" },
      },
      {
        path: "/data/sellingCoins",
        component: () => import("@/views/dataStatistics/sellingCoins"),
        meta: { title: "每日卖币" },
      },
      {
        path: "/data/withdrawal",
        component: () => import("@/views/dataStatistics/withdrawal"),
        meta: { title: "每日提现" },
      },
      {
        path: "/data/statistics",
        component: () => import("@/views/dataStatistics/statistics"),
        meta: { title: "全网统计" },
      },
    ],
  },
  // 银行卡管理
  {
    path: "/bankCard",
    component: Layout,
    meta: {
      title: "银行卡管理",
      icon: require("@/assets/imgs/icon_yinhangka.png"),
    },
    children: [{ path: "", component: () => import("@/views/bankCard") }],
  },
  {
    path: "/applyRecord",
    component: Layout,
    meta: {
      title: "卡片申请管理",
      icon: require("@/assets/imgs/icon_yinhangka.png"),
    },
    children: [{ path: "", component: () => import("@/views/cardApply") }],
  },
  {
    path: "/account",
    component: Layout,
    meta: {
      title: "系统设置",
      icon: require("@/assets/imgs/icon_shezhi.png"),
    },
    children: [{ path: "", component: () => import("@/views/account") }],
  },
  // 交易管理
  {
    path: "/transaction",
    component: Layout,
    redirect: "/transaction/recharge",
    meta: {
      title: "交易管理",
      icon: require("@/assets/imgs/icon_jiaoyiguanli.png"),
    },
    children: [
      {
        path: "/transaction/experienceGold",
        component: () => import("@/views/transactionManage/experienceGold"),
        meta: { title: "体验金" },
      },
      {
        path: "/transaction/recharge",
        component: () => import("@/views/transactionManage/recharge"),
        meta: { title: "充值" },
      },
      {
        path: "/transaction/withdrawal",
        component: () => import("@/views/transactionManage/withdrawal"),
        meta: { title: "提现" },
      },
      {
        path: "/transaction/transferMoney",
        component: () => import("@/views/transactionManage/transferMoney"),
        meta: { title: "转账" },
      },
      {
        path: "/transaction/buyCurrency",
        component: () => import("@/views/transactionManage/buyCurrency"),
        meta: { title: "买币" },
      },
      {
        path: "/transaction/coinSale",
        component: () => import("@/views/transactionManage/coinSale"),
        meta: { title: "卖币" },
      },
      {
        path: "/transaction/Transfer",
        component: () => import("@/views/transactionManage/Transfer"),
        meta: { title: "划转" },
      },
      {
        path: "/transaction/currencyManagement",
        component: () => import("@/views/transactionManage/currencyManagement"),
        meta: { title: "币种管理" },
      },
      {
        path: "/transaction/commission",
        component: () => import("@/views/transactionManage/commission"),
        meta: { title: "手续费" },
      },
      {
        path: "/transaction/cardInfo",
        component: () => import("@/views/transactionManage/cardInfo"),
        meta: { title: "银行明细" },
      },
    ],
  },
  // 用户管理
  {
    path: "/user",
    component: Layout,
    redirect: "/user/query",
    meta: {
      title: "用户管理",
      icon: require("@/assets/imgs/icon_yonghuguanli .png"),
    },
    children: [
      {
        path: "/user/query",
        component: () => import("@/views/userManage/query"),
        meta: { title: "用户查询" },
      },
      {
        path: "/user/list",
        component: () => import("@/views/userManage/list"),
        meta: { title: "用户列表" },
      },
      {
        path: "/user/KYCmanage",
        component: () => import("@/views/userManage/KYCmanage"),
        meta: { title: "KYC管理" },
      },
      {
        path: "/user/UserRelations",
        component: () => import("@/views/userManage/UserRelations"),
        meta: { title: "用户关系" },
      },
      {
        path: "/user/contactlist",
        component: () => import("@/views/userManage/contactlist"),
        meta: { title: "联系人列表" },
      },
      {
        path: "/user/Performanceinquiry",
        component: () => import("@/views/userManage/Performanceinquiry"),
        meta: { title: "业绩查询" },
      },
    ],
  },
  //节点申请
  {
    path: "/apply",
    component: Layout,
    redirect: "/apply/nodeManagement",
    meta: {
      title: "节点",
      icon: require("@/assets/imgs/icon_sass.png"),
    },
    children: [
      {
        path: "/apply/nodeManagement",
        component: () => import("@/views/nodeManagement/index"),
        meta: { title: "申请审核" },
      },
      {
        path: "/apply/Dividendallocation",
        component: () => import("@/views/nodeManagement/Dividendallocation"),
        meta: { title: "分红配置" },
      },
      {
        path: "/apply/userNode",
        component: () => import("@/views/nodeManagement/userNode"),
        meta: { title: "用户节点" },
      },
      {
        path: "/apply/nodeConfiguration",
        component: () => import("@/views/nodeManagement/nodeConfiguration"),
        meta: { title: "节点配置" },
      },
      {
        path: "/apply/NodeCardNumber",
        component: () => import("@/views/nodeManagement/NodeCardNumber"),
        meta: { title: "节点卡号" },
      },
    ],
  },
  //公告
  {
    path: "/Notice",
    component: Layout,
    redirect: "/Notice/noticeManagement",
    meta: {
      title: "公告",
      icon: require("@/assets/imgs/icon_sass.png"),
    },
    children: [
      {
        path: "/Notice/noticeManagement",
        component: () => import("@/views/noticeManagement/index"),
        meta: { title: "公告列表" },
      },
    ],
  },
  //轮播图
  {
    path: "/Swiper",
    component: Layout,
    redirect: "/Swiper/Swiper",
    meta: {
      title: "轮播图",
      icon: require("@/assets/imgs/icon_sass.png"),
    },
    children: [
      {
        path: "/Swiper/Swiper",
        component: () => import("@/views/Swiper/index"),
        meta: { title: "轮播列表" },
      },
    ],
  },
  //资产
  {
    path: "/Property",
    component: Layout,
    redirect: "/Property/PropertyIndex",
    meta: {
      title: "资产",
      icon: require("@/assets/imgs/icon_jiaoyiguanli.png"),
    },
    children: [
      {
        path: "/Property/wallet",
        component: () => import("@/views/PropertyIndex/wallet"),
        meta: { title: "用户钱包" },
      },
      {
        path: "/Property/Userwallet",
        component: () => import("@/views/PropertyIndex/Userwallet"),
        meta: { title: "钱包明细" },
      },
      {
        path: "/Property/hosting",
        component: () => import("@/views/PropertyIndex/hosting"),
        meta: { title: "托管" },
      },
      {
        path: "/Property/prerevenueRecords",
        component: () => import("@/views/PropertyIndex/prerevenueRecords"),
        meta: { title: "预收益记录" },
      },
	  {
	    path: "/Property/freezeFunds",
	    component: () => import("@/views/PropertyIndex/freezeFunds"),
	    meta: { title: "冻结资金" },
	  },
    ],
  },
  //参数设置
  {
    path: "/ParameterSettings",
    component: Layout,
    redirect: "/ParameterSettings",
    meta: {
      title: "设置",
      icon: require("@/assets/imgs/icon_sass.png"),
    },
    children: [
      {
        path: "/ParameterSettings/ParameSettings",
        component: () => import("@/views/ParameterSettings/ParameSettings"),
        meta: { title: "参数设置" },
      },
      {
        path: "/ParameterSettings/blacklist",
        component: () => import("@/views/ParameterSettings/blacklist"),
        meta: { title: "黑名单" },
      },
      {
        path: "/ParameterSettings/invitationPool",
        component: () => import("@/views/ParameterSettings/invitationPool"),
        meta: { title: "邀请奖池" },
      },
    ]
  },
];
function hasPermission(menus, route) {
  // console.log(menus,route,'menusmenusmenusmenusmenus');
  if (route && route.path) {
    return menus.some((menu) => route.path === menu.url);
  } else {
    return true;
  }
}

/* 计算动态路由 */
function calcRoutes(dynamicRoutes, menus) {
  // console.log(dynamicRoutes,'dynamicRoutes')
  // console.log(role)
  // 过滤
  let res = [];
  dynamicRoutes.forEach((route) => {
    const tmp = {
      ...route,
    };
    if (hasPermission(menus, tmp)) {
      if (tmp.children) {
        tmp.children = calcRoutes(tmp.children, menus);
      }
      res.push(tmp);
    }
  });
  return res;
}

// 计算菜单
function calcMenu(accessRoutes) {
  // let temp = {
  //   path: "/home",
  //   component: Layout,
  //   meta: {
  //     title: "后台首页",
  //     icon: require("@/assets/imgs/icon_shouye.png"),
  //   },
  //   children: [{ path: "", component: Home }],
  // };

  // if (accessRoutes) {
  //   return [temp,...accessRoutes];
  // }

  if (accessRoutes) {
    return [...accessRoutes];
  }
}

// 生成路由函数
export function createRouter(val) {
  let menus;
  // 取出用户的角色
  // let role = "super"; //这里先写死一个路由
  // // let role = local.get('role') // super normal

  // if (!role) return;

  // 计算出当前角色有权限访问的路由
  if (val && val.length > 0) {
    let accessRoutes = calcRoutes(dynamicRoutes, val);
    router.addRoutes(accessRoutes); // 动态路由 退出系统 必须刷新页面 否则之前一个角色的权限还在.

    // 计算菜单数据( 根据路由 计算 )
    // console.log(accessRoutes,'accessRoutes')
    menus = calcMenu(accessRoutes);
  } else {
    menus = calcMenu();
  }
  // console.log(menus,'menus')
  // console.log(val,'val')
  // 存入本地
  local.set("menus", menus);
  local.set("menus1", val);
}

// 刷新会调用
createRouter(local.get("menus1"));

export default router;
