import request from "@/utils/request";

//后台信息接口设置
export const getSetting = () =>
  request({
    url: "/bank_sys_setting/getSetting",
    method: "GET",
  });

//登录
export const login = (data) =>
  request({
    url: "/sys/l/login",
    method: "post",
    data,
  });

//获取登录用户详情
export const getLoginUser = () =>
  request({
    url: "/sys/l/getLoginUser",
    method: "GET",
  });

//获取登录用户菜单查询/sys/l/user_menus_notree
export const loginUserMenus = () =>
  request({
    url: "/sys/l/user_menus_notree",
    method: "GET",
  });

//退出登录
export const loginOut = (parpams) =>
  request({
    url: "/sys/l/logout",
    method: "get",
    parpams,
  });