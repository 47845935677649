import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 引入element-ui
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

// 引入重置样式
import "./assets/css/normalize.css";
import "./assets/css/reset.css";
import "./assets/css/common.css";
import "./assets/css/common.less";
// 引入iconfont.css
// import "./assets/fonts/iconfont.css";

import i18n from "./i18n"; // Internationalization
//引入一键复制功能
import VueClipBoard from "vue-clipboard2";
Vue.use(VueClipBoard);

Vue.use(ElementUI, {
  size: "medium", // set element-ui default size设置元素默认大小
  i18n: (key, value) => i18n.t(key, value), // 在注册Element时设置i18n的处理方法
});
// 引入全局组件
import DialogPop from "@/components/DialogPop";
import Pagination from "@/components/Pagination";
import Header from "@/components/Header";
// 注册为全局组件
Vue.component("DialogPop", DialogPop);
Vue.component("Pagination", Pagination);
Vue.component("Header", Header);

// 中介 帮助乱传数据 任意两个组件
Vue.prototype.$bus = new Vue();

// 在Vue中注入ElementUI
Vue.use(ElementUI);

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");
