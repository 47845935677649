export default {
  //路由相关
  router: {},
  //公共
  vabI18n: {
    系统菜单根节点:"System menu root node",
    首页: "Homepage",
    //设置//Settings
    后台设置: "Backend Configuration",
    菜单管理: "Menu Management",
    角色管理: "Role Management",
    权限管理: "Permission Management", // saas管理//SaaS Management
    SaaS管理: "SAAS Management",
    客户管理: "Customer Management",
    客户数据统计: "Customer Data Analysis", //数据统计  //Data Statistics
    每日充值: "Top-Up",
    每日转账: "Transfer",
    每日买币: "Buy coins",
    每日卖币: "Sell Coins",
    每日提现: "Withdrawal",
    全网统计: "Overall Statistics",
    //银行卡//Bank Card
    银行卡管理: "Card Management",
    系统设置: "System Configuration", // 交易管理//Transaction Management
    交易管理: "Transaction Mangement",
    充值: "Top-up",
    提现: "Withdrawal",
    转账: "Transfer",
    买币: "Buy Coins",
    卖币: "Sell Coins",
    币种管理: "Token Management", //用户管理//User Management
    用户管理: "User Management",
    用户查询: "User Search",
    用户列表: "User List",

    节点: 'Node',
    申请审核: 'Apply review',
    KYC管理: "KYC Management",
    确定: "Confirm",
    取消: "Cancel",
    操作: "Action",
    用户名: "Username",
    密码: "Password",
    保存: "Save",
    编辑: "Edit",
    更新: "Update",
    删除: "Delete",
    永久: "Permanent",
    过期: "Expired",
    消息: "Notification",
    邮箱: "Email",
    电话: "Phone",
    查询: "Search",
    更多: "More",
    编辑: "Edit",
    启动: "Start",
    启用: "Activate",
    禁用: "Deactivate",
    重置密码: "Reset Password",
    重置Google: "Reset Google",
    登录: "Login",
    忘记密码: "Forgot Password",
    还没有账户: "You don't have account yet.",
    请输入用户名: "Please enter username",
    免费注册: "Free Registration",
    输入邮箱: "Enter Email",
    请输入邮箱: "Please enter Email",
    邮箱验证码: "Email Verification Code",
    请输入邮箱验证码: "Please enter Email Verification Code",
    请输入密码: "Please Enter Password",
    重设密码: "Reset Password",
    输入手机号码: "Enter Phone No.",
    验证码: "Verification Code",
    请输入新密码: "Please Enter New Password",
    请输入手机号: "Please Enter Phone No.",
    请输入短信验证码: "Please enter sms verification code",
    发送验证码: "Sent OTP ",
    重新发送: "Resend",
    我同意本网站的: "I agree",
    条款和协议: "Terms and Condition",
    已有账号: "The account already exists",
    快速登录: "Quick login",
    新增: "Add",
    原因: "Reason",
    GoogleAuth验证码: "GoogleAuth Code",
    请输入GoogleAuth验证码: "Please enter GoogleAuth Code",
    此操作将永久删除:
      "This action will permanently delete the file. Continue?",
    提示: "Notification",
    删除成功: "Sucessfully Deleted",
    已取消删除: "Cancelled deletion",
    操作成功: "Successful",
    请选择: "Please Select",
    是: "Yes",
    否: "No",
    保存更改: "Save Changes",
    请输入: "Please Enter",
    抱歉: "Sorry!",
    当前页面不存在: "Current page not found...",
    请检查您输入的网址是否正确:
      "Please check if the URL you entered is correct, or click the button below to return to the homepage",
    返回首页: "Return to homepage", //sass//sass
    管理: "Management",
    客户列表: "Customer List",
    客户名: "Customer Name",
    Logo: "Logo",
    Google验证码: "Google Verification Code",
    状态: "Status",
    创建时间: "Creation Time",
    新增客户: "Add New Customer",
    今日数据: "Today's Data",
    启动: "Activate",
    禁用: "Deactivate",
    输入名称: "Enter Name",
    输入邮箱: "Enter Email",
    输入密码: "Enter Password",
    退出: "Logout",
    退出成功: "Logout Successfully", //首页//Home Page
    累计数据: "Cumulative Data",
    累计用户: "Cumulative Users",
    月: "Month",
    日: "Day",
    周: "Week",
    累计账户余额: "Cumulative Account Balance",
    累计账户充值: "Cumulative Account Top-ups",
    累计账户消费: "Cumulative Account Spending",
    累计加密账户提现: "Crypto-Account Withdrawals",
    累计加密账户余额: "Cumulative Crypto-Account Balance",
    累计加密账户充值: "Crypto-Account Deposits",
    新增用户: "New Users",
    账户充值: "Account Top-ups",
    账户消费: "Account Spending",
    加密账户充值: "Encrypted Account Recharge",
    加密账户提现: "Encrypted Account Withdrawals", //后台设置//Background Settings
    菜单树: "Menu Tree",
    您的交易: "Your Transactions",
    上级节点: "Parent Node",
    节点名称: "Node Name",
    节点路径: "Node Path",
    同级排序权重: "Sort Weight of the Same Level",
    权限:"Permission",
    权限管理: "Permission Management",
    用户列表: "User List",
    权限名称: "Permission Name",
    权限描述: "Permission Description",
    授权: "Authorization",
    操作表单: "Action Form",
    权限名称ROLE_开头全大写: "Permission name, starts with ROLE_, all uppercase",
    权限内容可访问的url:
      "Permission content, accessible URL, separated by commas when multiple",
    授权成功: "Authorization successful",
    角色设置: "Role settings",
    查询登录名: "Login Name Search",
    登录名: "Login name",
    重制密码: "Reset Password",
    初始重置密码: "Initial, Reset Password",
    是否允许登录系统: "Allow login to the system (soft delete)",
    是否允许多人在线: "Allow multiple users online",
    限制允许登录的IP集合: "Restrict allowed login IP Set",
    限制允许登录的IP几何:
      "Restrict allowed login IPs (multiple IPs separated by commas)",
    账号失效时间: "Account Expiration Time",
    最近修改密码时间: "Latest password change time",
    更新时间: "Update Time",
    请输入登录名: "Please enter login name",
    请输入登录密码: "Please enter login password",
    请选择用户类型: "Please select user type",
    请选择用户角色: "Please select user role",
    用户名称: "User Name",
    重置成功: "Reset successful!",
    重置失败: "Reset failed, please try again!", //数据统计// Data statistics
    每日充值: "Daily Top-Up",
    充值数据: "Top-up Data",
    代币名称: "Token Name",
    金额: " Amount",
    买币数据: "Buy Tokens Data",
    卖币数据: "Sell Tokens Data",
    充值提现: "Top-up and Withdraw",
    转账数据: "Transfer Data",
    全网统计: "Overall Statistics",
    统计数据: "Statistical Data",
    充值: "Top-up",
    提现: "Withdrawal",
    转账: "Transfer",
    买币: "Buy Coins",
    卖币: "Sell Coins", //银行卡管理//Bank Card Management
    银行卡管理: "Card Management",
    银行卡列表: "Card List",
    银行卡: "MasterCard",
    创建: "Create",
    激活: "Activate",
    分配: "Assign",
    银行卡号: "Card No. ",
    卡片类型: "Card Type",
    卡片状态: "Card Status",
    请输入名称: "Please Enter Name",
    请选择一条数据操作: "Please select an operation for the data",
    请选择需要操作的数据: "Please select the data to be operated on",
    物理卡卡号: "Physical Card No.",
    虚拟卡: "Virtual Card",
    物理卡: "Physical Card", //系统设置//System Settings
    系统设置: "System Configuration",
    安全码设置: "Security PIN Setup",
    Google验证器: "Google Authenticator",
    重制安全码: "Reset Security PIN",
    设置安全码: "Set Security PIN",
    修改谷歌验证器: "Change Google Authenticator",
    绑定谷歌验证器: "Bind Google Authenticator",
    新安全码: "New Security PIN",
    输入安全码: "Enter Security PIN",
    请输入6位数字: "Please enter 6-digit number",
    重复输入新安全码: "Re-enter new security PIN",
    重复输入安全码: "Re-enter security PIN",
    安全验证: "Security Verification",
    请输入验证码: "Please enter verification code",
    请输入手机号验证码:"Please enter the mobile phone number verification code",
    安全验证: "Security Verification",
    请妥善备份私钥以防遗失: "Please backup your private key properly to prevent loss!",
    地址: "Address",
    请输入安全验证码: "Please enter Security PIN",
    验证码不能为空: "Verification code cannot be blank",
    两次验证码必须一致: "The verification code must match",
    修改安全码: "Change Security PIN",
    已发送请注意查收: "Sent. Please check.",
    请选择安全验证方式: "Please select security verification method!",
    复制成功: "Copy Successful!",
    复制失败: "Copy Failed!",
    修改电话号码: "Change Phone No.",
    绑定电话号码: "Bind Phone No.",
    邮箱认证: "Email Verification",
    修改邮箱: "Change Email",
    绑定邮箱: "Bind Email",
    输入电话号码: "Enter Phone No.",
    请输入手机号国家代码: "Please enter mobile country code",
    请输入电话号码: "Please Enter Phone No.", //交易管理//Transaction management
    交易管理: "Transaction Management",
    充值列表: "Deposit List",
    交易类型: "Transaction Type",
    充值地址: "Deposit Address",
    交易Hash: "Transaction Hash",
    时间: "Time",
    提现列表: "Withdrawal list",
    到账地址: "Receiver Address",
    通过: "Approved",
    不通过: "Not Approved",
    转账列表: "Transfer list",
    到账账户: "Receiver Account",
    交易列表: "Transaction list",
    币种管理: "Token Management",
    代币LOGO: "Token LOGO",
    合约地址: "Contract Address",
    代币小数位: "Token Decimals",
    CoBo名称: "CoBo Name",
    处理中: "Processing",
    成功: "Success",
    失败: "Failed", // 用户管理// User management
    用户查询: "User Search",
    用户信息: "User Information",
    加密账户: "Cryptocurrency Account",
    累计充值: "Cumulative Deposit",
    累计提现: "Cumulative Withdrawal",
    Google验证: "Google Authentication",
    累计转账: "Cumulative Transfer",
    重制: "Reset",
    账户: "Account",
    标题: "Title",
    名字: "Name",
    中间名字: "Middle name",
    移动国家代码: "Mobile country code",
    出生日期: "Date of birth",
    电子邮件: "Email",
    电话号码: "Phone No.",
    国籍: "Nationality",
    用户姓氏: "Family Name",
    性别: "Gender",
    证件类型: "ID Type",
    KYC管理: "KYC Management",
    KYC全部: "Complete KYC",
    地址证明: "Proof of Address",
    身份证明: "Proof of Identity",
    未完成: "Incomplete",
    完成: "Complete",
    用户电话: "User Phone",
    审核状态: "Review Status",
    填写原因: "Fill in reason",
    KYC操作: "KYC Operation",
    未审核: "Not Reviewed",
    KYC身份编辑: "Edit KYC Identity",
    身份证: "ID",
    输入证件号码: "Enter ID No.",
    证件号码:"ID No. ",
    证件发行国家: "ID Issuance Country",
    过期时间: "Expiry Time",
    选择过期时间: "Select Expiry Time",
    文件上传: "Upload File",
    上传文件图片只能是图片格式:
      "Uploaded file must be in image format!",
    数字银行: "Digital Bank Mastercard",
    后台首页: "Backend Homepage",
    数据统计: "Data Statistics",
    开始日期: "Start Date",
    结束日期: "End Date",
    请输入物理卡号:"Please enter the physical card No.",
    至:"To",
    用户类型:"User Type",
    用户角色:"User Role",
    重置验证码:"Reset verification code",
    申请列表:"Application List",
    申请数据: 'Application Data',
    节点类型: 'Node Type',
    国家: 'Country',
    申请人名称: 'Applicant Name',
    申请邮箱: 'Application Email',
    邀请人:'Referrer',
    联系方式: 'Contact',
    审核状态: 'Review Status',
    审核通过:'Approved',
    审核不通过:'Not Approved',
    联合创始人: 'Co-Founder',
    特约合伙人: 'Special Partner',
    超级节点: 'Super Node',
    国际节点: 'International Node',
    社区节点: 'Community Node',
    待审核: 'Pending Review',
    虚拟节点:'Virtual Node',
    真实节点:'Real Node',
    是否设置为虚拟节点:'Set as Virtual Node',
    是否设置为真实节点:'Set as Real Node',
    节点状态:'Node Status',
    分红配置:'Dividend Configuration',
    划转:'Transfer',
    类型:'Type',
    资金池总额:'Total Pool Fund',
    分红币种:'Dividend Currency',
    是否开启:'Confirm Activate?',
    划转列表:'Transfer List',
    业务编号:'Business ID',
    用户邮箱:'User Email',
    银行账户:'Bank Account',
    手续费:'Transaction Fees',
    银行交易编号:'Bank Transaction ID',
    业务状态:'Business Status',
    请选择业务类型:'Please select business type',
    加密到银行:'From Crypto to Bank ',
    银行到加密:'From Bank  to Crypto ',
    用户ID:'User ID',
    团队总人数:'Total Team Member',
    直推人数:'Total Referrers',
    此操作将提交到银行是否继续:'This operation will be submitted to the bank, continue?',
    是否拒绝:'Do you want to reject?',
    已取消提交:'Submission is cancelled!',
    手续费分红:'Transaction Fees Dividend',
    生态池分红:'Pool Dividend',
    提交到银行:'Submit to Bank',
    拒绝:'Reject',
    今日新增用户: 'Daily New Users',
    昨日: 'Yesterday',
    交易用户: 'User Transaction',
    今日: 'Today',
    总用户量: 'Total Users ',
    本月新增: 'Monthly New User',
    今日托管数额: 'Todays Custodial Amount',
    今日加密货币交易数额: 'Todays Cryptocurrency Transaction Amount',
    今日银行交易数额: 'Todays Bank Transaction Amount',
    账户余额: 'Account Balance',
    异常信息:'Exception Message',
    用户节点:'User Node',
    用户数据: 'User Data',
    本月已完成团队业绩: 'Team performance completed this month',
    连续三月业绩完成状态: 'Completed performance for 3 consecutive months',
    未分红: 'Unallocated dividends',
    本月业绩是否完成: 'Is this months performance completed',
    本月需完成团队业绩: 'Team performance required to be completed this month',
    已赠送ERA: 'ERA is rewarded',
    价值:'value',
    应赠送总金额: 'Total reward amount',
    停用启用: 'Deactivate',
    团队等级: 'Team Level',
    业绩查询: 'Performance Query',
    停用: 'Disabled',
    日期:'Date',
    是否完成: 'Is it completed',
    需要完成的业绩: 'Performance to be completed',
    已完成的业绩: 'Completed Performance',
    节点配置:'Node Configuration',
    配置数据: 'Configuration Data',
    总名额: 'Total Quota',
    剩余名额: 'Remaining Quota',
    全网手续费分红比例: 'Global transaction fee dividend ratio',
    全网生态分红比例: 'Ecological dividend ratio of the whole network',
    每月需要完成的业绩: 'Monthly performance required to be completed',
    已获得名额: 'Obtained Quota',
    赠送金额: 'Reward Amount',
    赠送的银行卡名称: 'Rewarded MasterCards Name',
    申请金额: 'Application Amount',
    等级:'Level',
    公告:'Announcement',
    公告列表:'Announcement List',
    公告数据:'Announcement Data',
    阿拉伯文标题: 'Arabic Title',
    阿拉伯文内容: 'Arabic Content',
    中文标题: 'Chinese Title',
    中文内容:'Chinese Content',
    英文标题: 'English Title',
    英文内容: 'English Content',
    意大利语标题: 'Italian Title',
    意大利语内容: 'Italian Content',
    日文标题: 'Japanese Title',
    日文内容: 'Japanese Content',
    韩语标题: 'Korean Title',
    韩语内容: 'Korean Content',
    俄罗斯标题: 'Russian Title',
    俄罗斯内容: 'Russian Content',
    泰语标题: 'Thai Title',
    泰语内容: 'Thai Content',
    繁体中文标题: 'Traditional Chinese Title',
    繁体中文内容:'Traditional Chinese Content',
    越南语标题: 'Vietnamese Title',
    越南语内容: 'Vietnamese Content',
    印尼标题: 'Indonesian Title',
    印尼内容: 'Indonesian Content',
    修改: 'Edit',
    发布:'Publish',
    新增公告: 'Add Announcement',
    资产:'Asset',
    钱包明细: 'Wallet Detail',
    托管:'Custody',
    预收益记录: 'Estimated Earnings Record',
    用户钱包: 'User Wallet',
    钱包列表: 'Wallet List',
    业务类型: 'Business Type',
    钱包地址:'Users walletAddress',
    汇率:'Exchange Rate',
    主网: 'Mainnet',
    银行USD金额: 'Bank USD Amount',
    托管金额: 'Custodial Amount',
    托管类型: 'Custody Type',
    链名称: 'Chain Name',
    币种名称: 'Token Name',
    是否赎回: 'Confirm to redeem?',
    复投: 'Reinvest',
    复利: 'Compound Interest',
    已赎回:'Redeemed',
    未赎回: 'Unredeemed',
    预收益列表: 'Estimaed Earnings List',
    托管编号: 'Custody No.',
    收益:'Earnings',
    托管类型: 'Custody Type',
    已累计到本金: 'Accumulated Principal',
    托管:'Custody',
    领取编号: 'Claim No.',
    释放比例:'Release Ratio',
    领取奖励: 'Claim Reward',
    已领取:'Claimed',
    未领取:'Unclaim',
    托管列表:'Custody List',
    请选择状态: 'Please select status',
    备注:'Remarks',
    银行卡转账:'Card Transfer',
    消费:'Spending',
    转入:'Transfer',
    服务费:'Service Charges',
    充值银行:'Top-up Bank',
    充值加密货币:'Deposit Crypto',
    托管:'Custody',
    托管赎回:'Custodial Redemption',
    直推奖励:'Referral Reward',
    平级奖励:'Level Comission',
    奖金分红:'Bonus Dividend',
    贷款保证金:'Loan Collateral',
    贷款:'Loan',
    还款:'Repaymant',
    退还保证金:'Return Collateral',
    复利收益:'Compound Interest Earnings',
    托管收益:'Custodial Earnings',
    团队奖励:'Team Rewards',
    越级奖励:'Overriding Rewards',
    闪兑:'Flash Exchange',
    手续费分红:'Fees Dividend',
    生态池分红:'Ecological pool dividend',
    节点赠送:'Node gift',
    退款:'Refund',
    用户关系:'User Relationship',
    用户ID:'User ID',
    订单编号:'Order number',
    请输入ID或Email:'Please enter ID or Email',
    用户ID或EMAIL:'User ID or Email',
    用户昵称:'User Nickname',
    COBO名称:'COBO Name',
    余额:'Balance',
    奖励来源:'Source of rewards',
    银行明细:'Bank Details',
    账户ID:'Account ID',
    联系人ID:'Contact person ID',
    交易金额:'Transaction Amount',
    交易状态:'Transaction Status',
    是否同步:'Confirm to synchronize?',
    已同步:'Synchronized',
    未同步:'Not synchronized',
    手续费列表:'Transaction Fees List',
    币种ID:'Token ID',
    固定手续费:'Fixed Charges',
    最少数量:'Minimun Amount',
    手续费比例:'Transaction Fees Percentage',
    百分比或固定值:'Percentage or Fixed Value',
    使用百分比:'Use Percentage',
    使用固定值:'Use Fixed Value',
    币种链:'Token Chain',
    用户编号:'User ID',
    银行余额:'Bank Balance',
    钱包余额:'Wallet Balance',
    节点卡号:'Node card number',
    卡号数据:'Card Data',
    导入:'Import',
    卡号:'Card No.',
    使用人:'Card User',
    名称:'Name',
    是否使用:'Confirm to use?',
    上传:'Upload',
    点击上传:'Click Upload',
    卡类型:'Card Type',
    发布状态:'Publish Status',
    未发布:'Not Publish',
    发布中:'Publishing',
    已发布:'Published',
    轮播图:'Carousel',
    轮播列表:'Carousel List',
    图片路径:'Image v',
    语言:'Language',
    轮播图类型:'Carousel Type',
    请选择语言:'Please select Language',
    请选择轮播图类型:'Please select carousel type',
    替换或追加:'Replace or Append',
    替换所有:'Replace All',
    追加:'Append',
    新增轮播图:'Add New Carousel',
    编辑轮播图:'Edit Carousel',
    排序:'Sort',
    请排序:'Please Sort',
    设置:'Settings',
    参数设置:'Parameter settings',
    参数列表:'Parameter list',
    键:'Key',
    值:'Value',
    描述:'Description',
    输入键:'Input Key',
    输入值:'Input Value',
    输入描述:'Enter description',
    联系人:'Contact Person',
    联系人列表:'Contact List',
    联系人银行ID:'Contact Bank ID',
    联系人邮箱:'Contact Email',
    联系人名称:'Contact Name',
    标记等级:'Mark Level',
    修改等级:'Edit Level',
    钱包排序:'Wallet Sorting',
    月化收益率:'Monthly Return Rate',
    走势图路径:'Trend Chart Path',
    充值到银行卡:'Top-up MasterCard',
    银行卡到加密:'MasterCard to Crypto Accoun',
    每日复利收益比例:'Daily Compound Interest Rate',
    最大天数:'Maximum Number of Days',
    最小天数:'Minimum Number of Days',
    托管赎回手续费:'Custody Redemption Fee',
    托管每日收益比例:'Percentage of Daily Custody Return',
    允许:'Allow',
    不允许:'Not allow',
    链名:'Chain Name',
    输入链名:'Enter chain name',
    币种logo:'Token Logo',
    输入币种名称:'Enter Token name',
    输入COBO名称:'Enter COBO name',
    是否闪兑:'Whether to redeem instantly',
    请选择是否闪兑:'Please select whether to redeem instantly',
    走势GIF图地址:'Trend GIF Chart Address',
    请选择加密到银行:'Please select Crypto to Bank',
    请选择银行到加密:'Please select Bank to Crypto',
    小数位数:'Decimal Places',
    输入小数位数:'Enter decimal places',
    钱包列表排序:'Wallet List Sequence',
    输入钱包列表排序:'Enter wallet list sequence',
    托管月化收益率:'Monthly Custody Return',
    输入托管月化收益率:'Enter monthly return for custody',
    托管排序:'Custody Sequence',
    输入托管排序:'Enter custody sequence',
    是否允许托管:'Whether to allow for custody',
    可以:'Can',
    不可以:'Cannot',
    不可以闪兑:'Cannot be redeemed instantly',
    请选择等级:'Please select level',
    链:'Chain',
    代币:'Token',
    邀请奖池:'Referral Reward Pool',
    邀请奖池列表:'List of Referral Reward Pool',
    是否内部人员:'Whether is Internal Personnel',
    邀请人数:'Number of Refer',
    轮数:'Round',
    是否启用:'Whether to enable',
    黑名单:'Blacklist',
    黑名单列表:'List of Blacklist',
    昵称:'Nickname',
    邮箱或ID:'Email Address/ID',
    业绩查询列表:'Sales Enquiry List',
    业绩查询:'Sales Enquiry',
    团队业绩:'Group Sales',
    个人托管:'Individual Custody',
	邀请人数必须大于等于:'No. of invitation must be greater than',
	人:'Person',
	冻结资金:'Freeze asset',
	冻结资金列表:'List of Frozen Asset',
	剩余金额:'Balance Amount',
	冻结金额:'Freeze Amount',
	输入冻结金额:'Please enter frozen amount',
	Mastercard充值手续费:'Mastercard recharge fee',
	Mastercard月费:'Mastercard monthly fee',
	Mastercard转账手续费:'Mastercard transfer fee',
	Mastercard提现费用:'Mastercard withdrawal fees',
	Mastercard虚拟账户费用:'Mastercard Virtual Account Fees',
	Mastercard外汇费用:'Mastercard foreign exchange fees',
  },
};
